import React from "react";
import COLORS from "../data/colors";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../utils/normalize.css";
import "../utils/css/screen.css";
import styled from "styled-components";
import SiteConfig from "../../siteConfig";
import { useStore } from "../context/store";
import { Title } from "../components";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

const MapContainer = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  top: 120px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media (max-width: 850px) {
    left: 0;
  }
`;

const ContactIconsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px 0;

  @media (max-width: 850px) {
    flex-direction: column;
    padding: 40px 0 0;
  }
`;

const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: auto;
    margin-bottom: 8px;
  }

  .type {
    font-family: Cormorant Garamond;
    font-size: 18px;
  }

  div {
    text-align: center;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 8px;
  }

  svg {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 850px) {
    margin: 0 0 16px 0;
  }
`;

const ContentContainer = styled.div`
  margin: 500px 16px 0;
  padding-top: 48px;
`;

const Contact = () => {
  const { translations } = useStore();
  const {
    contactPhone,
    contactPhoneDisplay,
    addressLine1,
    addressLine2,
    rocioEmail,
    juanitaEmail,
  } = SiteConfig;

  const { site, officeImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        officeImage: file(name: { eq: "IMG_61" }) {
          childImageSharp {
            fluid(maxWidth: 1360, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout title={site.siteMetadata.title}>
      <SEO
        title={translations.call_or_visit}
        // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <MapContainer>
            <iframe
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(Maya%20Seguros%20y%20Mas)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
            />
          </MapContainer>
          <ContentContainer>
            <Title
              title={translations.call_or_visit}
              subtitle={translations.outside_normal_business_hours}
            />

            <div
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                marginTop: 24,
              }}
            >
              <GatsbyImage fluid={officeImage.childImageSharp.fluid} />
            </div>
            <ContactIconsContainer>
              <ContactItem>
                <div className="icon">{getIcon("clock")}</div>
                <div className="type">{translations.open}</div>
                <div>9 AM - 6 PM</div>
                <div>{translations.days_open}</div>
              </ContactItem>
              <ContactItem>
                <div className="icon">{getIcon("phone")}</div>
                <div className="type">{translations.phone}</div>
                <div>
                  <a
                    style={{ color: COLORS.link }}
                    href={`tel:${contactPhone}`}
                  >
                    {contactPhoneDisplay}
                  </a>
                </div>
                <div> &nbsp;</div>
              </ContactItem>
              <ContactItem>
                <div className="icon">{getIcon("email")}</div>
                <div className="type">{translations.email}</div>
                <div>
                  <a
                    style={{ color: COLORS.link }}
                    href={`mailto:${rocioEmail}`}
                  >
                    {rocioEmail}
                  </a>
                </div>
                <div>
                  <a
                    style={{ color: COLORS.link }}
                    href={`mailto:${juanitaEmail}`}
                  >
                    {juanitaEmail}
                  </a>
                </div>
                <div> &nbsp;</div>
              </ContactItem>
              <ContactItem>
                <div className="icon">{getIcon("address")}</div>
                <div className="type">{translations.address}</div>
                <a
                  style={{ color: COLORS.link }}
                  href="http://maps.apple.com/?daddr=429+W+12+St,+Dallas+TX+75208"
                >
                  <div>{addressLine1}</div>
                  <div>{addressLine2}</div>
                </a>
              </ContactItem>
            </ContactIconsContainer>
          </ContentContainer>
        </div>
      </article>
    </Layout>
  );
};

export default Contact;

function getIcon(name: string) {
  switch (name) {
    case "clock":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="300px"
          width="300px"
          fill={COLORS.icon}
          x="0px"
          y="0px"
          viewBox="0 0 368 368"
        >
          <g>
            <g>
              <g>
                <path d="M184,60c4.4,0,8-3.6,8-8v-4c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v4C176,56.4,179.6,60,184,60z" />
                <path d="M184,308c-4.4,0-8,3.6-8,8v4c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-4C192,311.6,188.4,308,184,308z" />
                <path d="M52,176h-4c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h4c4.4,0,8-3.6,8-8C60,179.6,56.4,176,52,176z" />
                <path d="M320,176h-4c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h4c4.4,0,8-3.6,8-8C328,179.6,324.4,176,320,176z" />
                <path
                  d="M93.6,82.4c-3.2-3.2-8-3.2-11.2,0c-3.2,3.2-3.2,8,0,11.2l2.8,2.8c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4
				c3.2-3.2,3.2-8,0-11.2L93.6,82.4z"
                />
                <path
                  d="M85.2,271.6l-2.8,2.8c-3.2,3.2-3.2,8,0,11.2C84,287.2,86,288,88,288s4-0.8,5.6-2.4l2.8-2.8c3.2-3.2,3.2-8,0-11.2
				S88.4,268.4,85.2,271.6z"
                />
                <path
                  d="M274.4,82.4l-2.8,2.8c-3.2,3.2-3.2,8,0,11.2c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4l2.8-2.8c3.2-3.2,3.2-8,0-11.2
				C282.4,79.2,277.6,79.2,274.4,82.4z"
                />
                <path
                  d="M192,180.8V108c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v76c0,2,0.8,4,2.4,5.6l87.6,87.6c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4
				c3.2-3.2,3.2-8,0-11.2L192,180.8z"
                />
                <path
                  d="M184,0C82.4,0,0,82.4,0,184s82.4,184,184,184s184-82.4,184-184S285.6,0,184,0z M184,352c-92.8,0-168-75.2-168-168
				S91.2,16,184,16s168,75.2,168,168S276.8,352,184,352z"
                />
              </g>
            </g>
          </g>
        </svg>
      );

    case "email":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="300px"
          width="300px"
          fill={COLORS.icon}
          x="0px"
          y="0px"
          viewBox="0 0 74 74"
        >
          <path d="m71 60.192h-68a1 1 0 0 1 -1-1v-44.36a1 1 0 0 1 1-1h68a1 1 0 0 1 1 1v44.36a1 1 0 0 1 -1 1zm-67-2h66v-42.36h-66z" />
          <path d="m37 42.719a14.454 14.454 0 0 1 -9.924-3.92l-24.761-23.262a1 1 0 0 1 1.37-1.458l24.76 23.262a12.518 12.518 0 0 0 17.11 0l24.76-23.262a1 1 0 0 1 1.37 1.458l-24.761 23.263a14.454 14.454 0 0 1 -9.924 3.919z" />
          <path d="m3 60.187a1 1 0 0 1 -.641-1.768l25.007-20.886a1 1 0 1 1 1.282 1.535l-25.007 20.886a1 1 0 0 1 -.641.233z" />
          <path d="m71 60.187a1 1 0 0 1 -.641-.233l-25.007-20.886a1 1 0 1 1 1.282-1.535l25.007 20.886a1 1 0 0 1 -.641 1.768z" />
        </svg>
      );

    case "address":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="300px"
          width="300px"
          fill={COLORS.icon}
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
        >
          <g>
            <path
              d="M489.802,497.135l-64-149.333c-1.677-3.927-5.531-6.469-9.802-6.469h-95.09l66.538-121.104
				c11.698-21.667,17.885-46.177,17.885-70.896C405.333,66.99,338.344,0,256,0S106.667,66.99,106.667,149.333
				c0,24.719,6.188,49.229,17.927,70.958l66.5,121.042H96c-4.271,0-8.125,2.542-9.802,6.469l-64,149.333
				c-1.417,3.292-1.073,7.073,0.896,10.073C25.073,510.198,28.417,512,32,512h448c3.583,0,6.927-1.802,8.906-4.792
				C490.875,504.208,491.219,500.427,489.802,497.135z M143.323,210.083C133.302,191.531,128,170.521,128,149.333
				c0-70.583,57.417-128,128-128s128,57.417,128,128c0,21.188-5.302,42.198-15.281,60.688L256,415.177L143.323,210.083z
				 M48.177,490.667l54.854-128h99.783l43.842,79.802c1.865,3.417,5.448,5.531,9.344,5.531s7.479-2.115,9.344-5.531l43.845-79.802
				h99.78l54.854,128H48.177z"
            />
            <path
              d="M341.333,149.333C341.333,102.281,303.052,64,256,64s-85.333,38.281-85.333,85.333c0,47.052,38.281,85.333,85.333,85.333
				S341.333,196.385,341.333,149.333z M192,149.333c0-35.292,28.708-64,64-64s64,28.708,64,64c0,35.292-28.708,64-64,64
				S192,184.625,192,149.333z"
            />
          </g>
        </svg>
      );

    case "phone":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="300px"
          width="300px"
          fill={COLORS.icon}
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M366,0H146c-20.678,0-37.5,16.822-37.5,37.5v437c0,20.678,16.822,37.5,37.5,37.5h220c20.678,0,37.5-16.822,37.5-37.5v-437
			C403.5,16.822,386.678,0,366,0z M388.5,407H176c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h212.5v52.5
			c0,12.407-10.093,22.5-22.5,22.5H146c-12.407,0-22.5-10.093-22.5-22.5V422H146c4.142,0,7.5-3.358,7.5-7.5
			c0-4.142-3.358-7.5-7.5-7.5h-22.5V75h265V407z M388.5,60h-265V37.5c0-12.407,10.093-22.5,22.5-22.5h220
			c12.407,0,22.5,10.093,22.5,22.5V60z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M286,30h-30c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h30c4.142,0,7.5-3.358,7.5-7.5
			C293.5,33.358,290.142,30,286,30z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M256,437c-12.407,0-22.5,10.093-22.5,22.5c0,12.407,10.093,22.5,22.5,22.5s22.5-10.093,22.5-22.5
			C278.5,447.093,268.407,437,256,437z M256,467c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5
			S260.136,467,256,467z"
              />
            </g>
          </g>
          <g>
            <g>
              <circle cx="226" cy="37.5" r="7.5" />
            </g>
          </g>
        </svg>
      );
    default:
      null;
  }
}
